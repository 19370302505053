import { lazy, Suspense } from 'react'
import { Notification } from './components/general-components/notification/Notification'
import AppFallback from './components/user-components/AppFallback'
import { SocketContextProvider } from './context/SocketContext'
import useSessionExpiration from './utils/useSessionExpiration'

const AppRoutes = lazy(() => import('./AppRoutes'))
export default function App() {
  useSessionExpiration()
  return (
    <SocketContextProvider>
      <Notification>
        <Suspense fallback={<AppFallback />}>
          <AppRoutes />
        </Suspense>
      </Notification>
    </SocketContextProvider>
  )
}
