import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { Author, FeedItemInterface, FeedItemResponseInterface } from './postsNew'
import { UserInterface } from './userNew'
import { CommentDTO, FEED_TYPE } from './feedSlice'
import { StringIterator } from 'lodash'

interface CreateActivityDTO extends FeedItemInterface {
  startDate: string
}

export interface ActivityExtension {
  startDate: string
}

export type ActivityDTO = FeedItemResponseInterface & ActivityExtension

interface State {}
export const initialState: State = {}
//#region methods

export const attemptPublishActivity = createAsyncThunk<ActivityDTO, CreateActivityDTO>(
  'activities/publish',
  async (payload) => {
    const response = await $api.post(`activity`, payload)
    return response.data
  },
)

type ActivityCommentDTO = CommentDTO
export type ActivityComment = {
  author: Author
  authorIdentity: string //TODO: ? what is this prop for ?
  repliedTo?: string
  createdAt: string
  entity: string
  text: string
  _id: string
}
export const attemptCommentActivity = createAsyncThunk<void, ActivityCommentDTO>( //TODO: avoid any
  'activities/comment',
  async (payload) => {
    const response = await $api.post(`comment`, payload)
    // return response.data
  },
)

export const attemptGetCommentsActivity = createAsyncThunk<ActivityComment[], string>(
  'activities/get-comments',
  async (id) => {
    const response = await $api.get(`comment/${id}`)
    console.log('attemptGetCommentsActivity response: ', response)
    return response.data
  },
)

//#endregion
//#region slice

export const activitySlice = createSlice({
  name: 'activitySlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export const {} = activitySlice.actions
export default activitySlice.reducer
