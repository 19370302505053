import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { ActivityDTO, attemptPublishActivity } from './activitySlice'
import { attemptPublishPost, PostDTO } from './postsNew'
import { attemptPublishService, ServiceDTO } from './publishedServices'
import _ from 'lodash'
import { useDev } from 'dev-masters-react-kit'
const feed: FeedEntry[] = [
  /* My data */
]
export interface FeedEntry {
  activity?: ActivityDTO
  service?: ServiceDTO
  post?: PostDTO
}

export enum FEED_TYPE {
  activity = 'activity',
  service = 'service',
  post = 'post',
}

export interface CommentDTO {
  entity: string //id
  text: string
  repliedTo?: string //id
}
// export type FeedEntry =
//   | { activity: ActivityDTO; service?: undefined; post?: undefined }
//   | { service: ServiceDTO; activity?: undefined; post?: undefined }
//   | { post: PostDTO; activity?: undefined; service?: undefined }

interface FetchFeedDTO {
  olderThan?: string //dayjs.Dayjs.toISOString()
  limit: number
}

interface State {
  feed?: FeedEntry[]
  hasMore?: boolean
}

export const initialState: State = {}
//#region methods

export const attemptFetchFeed = createAsyncThunk<FeedEntry[], FetchFeedDTO>(
  'feed/fetch',
  async (payload, { getState, dispatch }) => {
    const response = await $api.post(`feed`, payload)
    return response.data
  },
)

export const attemptRefreshFeed = createAsyncThunk<
  FeedEntry[],
  Omit<FetchFeedDTO, 'olderThan'>
>('feed/refresh', async (payload, { getState, dispatch }) => {
  const response = await $api.post(`feed`, payload)
  // await useDev().delay(3000)
  return response.data
})

// export const getIdentity = createAsyncThunk<UserIdentityInterface>(
//   'profile/get-information',
//   async (payload) => {
//     const response = await $api.get('/profile')
//     return response.data
//   },
// )

//#endregion
//#region slice

export const feedSlice = createSlice({
  name: 'feedSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attemptFetchFeed.pending, (state, { payload }) => {
      state.hasMore = undefined
    })
    builder.addCase(attemptFetchFeed.fulfilled, (state, { payload }) => {
      if (payload.length === 0) {
        state.hasMore = false
      } else {
        state.hasMore = true
      }
      const merged = _.merge(_.keyBy(state.feed, '_id'), _.keyBy(payload, '_id'))
      const uniqueFeed = _.values(merged)
      // uniqueFeed.shift() //to test attemptRefreshFeed
      state.feed = uniqueFeed
    })
    builder.addCase(attemptRefreshFeed.fulfilled, (state, { payload }) => {
      const merged = _.merge(_.keyBy(payload, '_id'), _.keyBy(state.feed, '_id'))
      const uniqueFeed = _.values(merged)
      state.feed = uniqueFeed
    })
    //#region external slice listeners
    builder.addCase(attemptPublishActivity.fulfilled, (state, { payload }) => {
      console.log('Start implementation of updating newsfeed on activity published')
    })
    builder.addCase(attemptPublishPost.fulfilled, (state, { payload }) => {
      console.log('Start implementation of updating newsfeed on post published')
    })
    builder.addCase(attemptPublishService.fulfilled, (state, { payload }) => {
      console.log('Start implementation of updating newsfeed on service published')
    })
    //#end region
  },
})

export const {} = feedSlice.actions
export default feedSlice.reducer
