import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PublishPostHttp } from '../http/publish-post-http'
import {
  CommentInterface,
  GetAllPostInterface,
  PinedPostType,
  PostUserInterface,
} from '../types/types'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { UserInterface } from './userNew'

export interface FeedItemInterface {
  title: string
  text: string
  images: string[]
  privacy: PRIVACY
  address: AddressDTO
}

export type Author = Pick<UserInterface, 'email' | 'avatarFileName' | 'fullName'>
export interface FeedItemResponseInterface extends FeedItemInterface {
  statistic: {
    clickedBy: string[]
    commentsCount: number
    shareCount: number
    viewedBy: string[]
  }
  createdAt: string
  deleted: boolean
  author: Author
  _id: string
}

type CreatePostDTO = FeedItemInterface
export type PostDTO = FeedItemResponseInterface

interface State {}
export const initialState: State = {}
//#region methods

export const attemptPublishPost = createAsyncThunk<PostDTO, CreatePostDTO>(
  'posts/publish',
  async (payload) => {
    const response = await $api.post(`post`, payload)
    return response.data
  },
)

//#endregion
//#region slice

export const postsReducer = createSlice({
  name: 'postsReducer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export const {} = postsReducer.actions
export default postsReducer.reducer
